export const SET_USER = "SET_USER";
export const SET_COMPANY = "SET_COMPANY";
export const SET_USER_DATA = "SET_USER_DATA";
export const RESET_USER = "RESET_USER";
export const RESET_STATE = "RESET_STATE";
export const SET_STATE = "SET_STATE";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";
export const SET_MENU = "SET_MENU";
export const SET_ACCESS = "SET_ACCESS";
export const SET_EXPIRY = "SET_EXPIRY";
export const SET_USER_LOCATION= "SET_USER_LOCATION";

