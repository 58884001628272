import store from '@/app/store';


export default function routePermission({next, to, router}) {
    const fromRoute = store.state.menu;
    const toRoute = to.name;
    const checked = fromRoute.includes(toRoute);

    if (!checked) {
        return router.push({name: 'dashboard'});
    } else {
        next();
    }
}