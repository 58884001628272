export default {
    login: 'Sign In',
    'forget-password': 'Forget Password',
    'reset-password': 'Reset Password',
    dashboard: 'Dashboard',
    sales_dashboard: 'Sales Dashboard',
    'dashboard.approve': 'Approve',
    profile: 'My Profile',
    'integrations.quickbook.form': 'Quickbook',
    'settings.employees.list': 'Employees',
    'settings.employees.create': 'Crate New Employee',
    'settings.employees.edit': 'Edit Employee',
    'settings.taxes.list': 'Tax Rules',
    'settings.taxes.create': 'Create Tax Rules',
    'settings.taxes.edit': 'Edit Tax Rules',
    'settings.ecommerce.list': 'Ecommerce Accounts',
    'settings.ecommerce.create': 'Create Ecommerce Accounts',
    'settings.ecommerce.edit': 'Edit Ecommerce Accounts',
    'settings.locations.list': 'Locations',
    'settings.locations.create': 'Crate New Location',
    'settings.locations.edit': 'Edit Location',
    'settings.store-setting.form': 'Store Setting',
    'settings.categories.list': 'Employee Category',
    'settings.v2.logistic-providers.list': 'Logistic Providers',
    'settings.scan-types.list': 'Scan Types',
    'settings.scan-types.create': 'Creat Scan Types',
    'settings.scan-types.edit': 'Edit Scan Types',
    'settings.couriers.list': 'Courier',
    'settings.couriers.create': 'Create Courier',
    'settings.couriers.edit': 'Edit Courier',
    'marketing.lucky-draws.list': 'Lucky Draw',
    'marketing.lucky-draws.create': 'Create Lucky Draw',
    'marketing.lucky-draws.edit': 'Edit Lucky Draw',
    'marketing.lucky-draw-users.list': 'Lucky Draw User',
    'register.luckydraw.user': 'Redeem Voucher Code',

    'settings.approval-limits.list': 'Approval Limits',
    'settings.approval-limits.create': 'Creat Approval Limit',
    'settings.approval-limits.edit': 'Edit Approval Limit',

    'settings.approval-rules.list': 'Approval Rules',
    'settings.approval-rules.create': 'Creat Approval Rule',
    'settings.approval-rules.edit': 'Edit Approval Rule',

    'settings.payment-terms.create': 'Create New Payment term',
    'settings.payment-terms.edit': 'Edit Payment term',
    'settings.payment-terms.list': 'Payment term',
    'settings.areas.list': 'Areas',
    'settings.areas.create': 'Create Area',
    'settings.areas.edit': 'Edit Area',
    'settings.item-locations.list': 'Item Location',
    'settings.item-locations.create': 'Create Item Location',
    'settings.item-locations.edit': 'Edit Item Location',
    'settings.lucky-draws-gift-types.list': 'Lucky Draw Gift Type',
    'settings.lucky-draws-gift-types.create': 'Create Lucky Draw Gift Type',
    'settings.lucky-draws-gift-types.edit': ' Edit Lucky Draw Gift Type',
    logout: 'Logout',
    notFound: 'Page Not Found',
    'accounts.customers.list': 'Customers',
    'accounts.companies.list': 'Companies',
    'accounts.companies.create': 'Create New Company',
    'accounts.companies.edit': 'Edit Company',
    'accounts.companies.delete': 'Delete Company',
    'accounts.account-payables.list': 'Account Payable',
    'accounts.account-payables.create': 'Create Account Payable',
    'accounts.account-payables.edit': 'Edit Account Payable',
    'accounts.consignment-notes.list': 'Consignment Notes',
    'accounts.supplier.list': 'Suppliers',
    'accounts.supplier.create': 'Create New Supplier',
    'accounts.supplier.edit': 'Edit Supplier',
    'accounts.supplier.delete': 'Delete Supplier',
    'accounts.credit-notes.list': 'Credit Note',
    'accounts.credit-notes.create': 'Create Credit Note',
    'accounts.credit-notes.edit': 'Edit Credit Note',
    'accounts.debit-notes.list': 'Debit Note',
    'accounts.debit-notes.create': 'Create Debit Note',
    'accounts.debit-notes.edit': 'Edit Debit Note',

    'items.item.list': 'Items',
    'items.item.create': 'Create New Item',
    'items.item.edit': 'Edit Item',
    'items.item.barcode': 'Item Barcode',
    'items.item.transaction-log': 'Inventory Transaction Log',
    'items.item.delete': 'Delete Item',
    'items.item.list-details': 'Item Details Report',
    'items.item.sales-report': 'Item Sales Report',
    'items.item.sales-graph': 'Item sales Graph',
    'items.categories.create': 'Create New Item Category',
    'items.categories.list': 'Item Category',
    'items.item-kits.list': 'Item Kits',
    'items.item-kits.create': 'Create New',
    'items.item-kits.update': 'Edit',

    'items.receiving.list': 'Receivings',
    'items.receiving.create': 'Create Receivings',
    'items.receiving.edit': 'Update Receivings',
    'items.stock-transfer.list': 'Stock Transfer',
    'items.stock-take.list': 'Stock Take',
    'items.stock-take.create': 'Create New Stock Take',
    'items.stock-take.multiple-item.create': 'Create New Stock Take Multiple Item',
    'items.stock-transfer.create': 'New Stock Transfer',
    'items.stock-transfer.edit': 'Update Stock Transfer',
    'items.item.carton-inventory': 'Carton Inventory',
    'items.tags.list': 'Tags',
    'items.tags.create': 'Create Tag',
    'items.tags.edit': 'Edit Tag',
    'items.price-groups.list': 'Price Groups',
    'items.price-groups.create': 'Create Price Group',
    'items.price-groups.edit': 'Edit Price Group',
    'items.unit-of-measure.list': 'Unit Of Measure',
    'items.unit-of-measure.create': 'Create Unit Of Measure',
    'items.unit-of-measure.edit': 'Edit Unit Of Measure',
    'items.purchase-orders.list': 'Purchase Order',
    'items.purchase-orders.create': 'Create New Order',
    'items.purchase-orders.edit': 'Edit Purchase Order',

    'items.purchase-order-drafts.list': 'POD',
    'items.purchase-order-drafts.create': 'POD Create',
    'items.purchase-order-drafts.edit': 'POD Edit',
    'items.item.inventory': 'Transaction Log',
    'items.item.summary': 'Item Summary',
    'items.quick-edit-items.list': 'Quick Edit Items',
    'items.quick-edit-price-group.list': 'Items price group',
    'items.quick-edit-item-location.list': 'Quick Edit Item Location',
    'items.groups.list': 'Item Groups',
    'items.groups.create': 'Create Group',
    'items.groups.edit': 'Edit Group',
    'items.item.stock-report': 'Item Stock Report',
    'items.job-orders.list': 'Job Order',
    'items.job-orders.create': 'Create New Job Order',
    'items.job-orders.edit': 'Edit Job Order',
    'items.stock-count-jobs': 'Stock Count Job',
    'items.stock-count-activity': 'Stock Count Activity',


    'reports.job-orders': 'Job Order Report',

    'sales.quotations.list': 'Quotations',
    'sales.quotations.create': 'Create New Quotation',
    'sales.quotations.edit': 'Edit Quotation',
    'sales.proforma-invoice.list': 'Proforma invoice',
    'sales.proforma-invoice.create': 'Create New invoice',
    'sales.proforma-invoice.edit': 'Edit invoice',
    'sales.invoices.list': 'Invoices',
    'sales.invoices-analysis-list': 'Invoice Analytics List',
    'sales.invoices.edit': 'Edit Invoices',
    'sales.delivery-orders.list': 'Delivery Orders',
    'sales.delivery-orders.edit': 'Edit Delivery Orders',
    'sales.sales-orders.list': 'Sales Orders',
    'sales.ecommerce-invoices.list': 'Ecommerce Invoice',
    'sales.sales-orders.analysis-list': 'Sames order analysis',
    'sales.pos.main': 'Pos',
    'sales.consignment.main': 'Consignment',

    'scan-station.scan.list': 'Scan station',
    'scan-station.scan.invoice': 'Scan Invoice',
    'scan-station.scan.delivery-order': 'Scan DO',
    'scan-station.scan.proforma-invoice': 'Scan Proforma Invoice',
    'scan-station.scan.ecommerce-invoice': 'Scan Ecommerce Invoice',
    'scan-station.scan.job-order': 'Scan Job Order',
    'scan-station.scan.stock-transfer': 'Scan Stock Transfer',
    'scan-station.scan.deactive-barcode': 'Deactive Carton',
    'scan-station.scan.stock-count': 'Stock Count',
    'scan-station.scan.awb': 'AWB',
    // 'reports.sales': 'Reports',

    'reports.list': 'Reports',
    'reports.sales': 'Sales Detailed Report',
    'reports.account-statement': 'Statement of Accounts',
    'reports.inventory-snapshot': 'Inventory Snapshot',
    'reports.scan-stock-count': 'Scan Stock Count Report',
    'reports.scan-stock-count-detail': 'Scan Stock Count Report Detail',
    'reports.negative-stock': 'Negative Stock',
    'reports.sales.invoice-scan': 'Scan Invoice Report',
    'reports.sales.ecommerce-invoice-scan': 'Scan Ecommerce Invoice Report',
    'reports.sales.ecommerce-sales': 'Ecommerce Sales Report',
    'reports.category-sales': 'Sales Report',
    'reports.awb': 'AWB',

    'module-dashboards.sales-rep-dashboard': 'Sales Person Report',
    'module-dashboards.items-dashboard': 'Items',
    'module-dashboards.customer-analysis-dashboard': 'Customers',
    'module-dashboards.purchase-orders-dashboard': 'PO',
    'module-dashboards.item-performance': 'Item Performance Report',
    'module-dashboards.recent-item-sales-analysis': 'Item Performance analysis',
}
