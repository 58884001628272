export const sidebar = function () {
    return [
        {
            name: 'Sales',
            icon: 'fas fa-cogs',
            route: 'sales.quotations.list',
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'Quotation',
                    icon: 'fas fa-file-contract',
                    route: 'sales.quotations.list',
                    isActive: true,
                },
                {
                    title: 'Proforma Invoice',
                    icon: 'fas fa-file-contract',
                    route: 'sales.proforma-invoice.list',
                    isActive: true,
                },
                {
                    title: 'Sales Order',
                    icon: 'fas fa-file-contract',
                    route: 'sales.sales-orders.list',
                    isActive: true,
                },
                {
                    title: 'Invoice',
                    icon: 'fas fa-file-contract',
                    route: 'sales.invoices.list',
                    isActive: true,
                },
                {
                    title: 'Ecommerce Invoice',
                    icon: 'fas fa-file-contract',
                    route: 'sales.ecommerce-invoices.list',
                    isActive: true,
                },
                {
                    title: 'Delivery Order',
                    icon: 'fas fa-file-contract',
                    route: 'sales.delivery-orders.list',
                    isActive: true,
                },

                {
                    title: 'POS',
                    icon: 'fas fa-file-contract',
                    route: 'sales.pos.main',
                    isActive: true,
                },

                {
                    title: 'Consignment',
                    icon: 'fas fa-file-contract',
                    route: 'sales.consignment.main',
                    isActive: true,
                },
                {
                    title: 'Prepare Goods',
                    icon: 'fas fa-file-contract',
                    route: 'sales.prepare-goods.list',
                    isActive: true,
                },
            ],
        },

        {
            name: 'Accounts',
            icon: 'fas fa-suitcase',
            route: null,
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'Customers',
                    icon: 'fas fa-people-arrows',
                    route: 'accounts.customers.list',
                    isActive: true,
                },
                {
                    title: 'Suppliers',
                    icon: 'fas fa-people-arrows',
                    route: 'accounts.supplier.list',
                    isActive: true,
                },
                {
                    title: 'Companies',
                    icon: 'fas fa-building',
                    route: 'accounts.companies.list',
                    isActive: true,
                },
                {
                    title: 'Credit Note',
                    icon: 'fas fa-file-contract',
                    route: 'accounts.credit-notes.list',
                    isActive: true,
                },
                {
                    title: 'Debit Note',
                    icon: 'fas fa-file-contract',
                    route: 'accounts.debit-notes.list',
                    isActive: true,
                },
                {
                    title: 'Account Payable',
                    icon: 'fas fa-file-contract',
                    route: 'accounts.account-payables.list',
                    isActive: true,
                },
                {
                    title: 'Consignment Notes',
                    icon: 'fas fa-file-contract',
                    route: 'accounts.consignment-notes.list',
                    isActive: true,
                },
            ],
        },
        {
            name: 'Item',
            icon: 'fas fa-wine-bottle',
            route: null,
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'Items',
                    icon: 'fas fa-gift',
                    route: 'items.item.list',
                    isActive: true,
                },
                // {
                //     title: 'Unit Of Measure',
                //     icon: 'fas fa-calculator',
                //     route: 'items.unit-of-measure.list',
                //     isActive: true,
                // },

                {
                    title: 'Item Kits',
                    icon: 'fas fa-gifts',
                    route: 'items.item-kits.list',
                    isActive: true,
                },
                {
                    title: 'Receiving',
                    icon: 'fas fa-warehouse',
                    route: 'items.receiving.list',
                    isActive: true,
                },
                {
                    title: 'Purchase Order',
                    icon: 'fas fa-warehouse',
                    route: 'items.purchase-orders.list',
                    isActive: true,
                },
                {
                    title: 'Purchase Order Drafts',
                    icon: 'fas fa-warehouse',
                    route: 'items.purchase-order-drafts.list',
                    isActive: true,
                },
                {
                    title: 'Job Order',
                    icon: 'fas fa-warehouse',
                    route: 'items.job-orders.list',
                    isActive: true,
                },
                {
                    title: 'Stock Take',
                    icon: 'fas fa-warehouse',
                    route: 'items.stock-take.list',
                    isActive: true,
                },
                {
                    title: 'Stock Transfer',
                    icon: 'fas fa-dolly',
                    route: 'items.stock-transfer.list',
                    isActive: true,
                },
                {
                    title: 'Item Price Group',
                    icon: 'fas fa-dolly',
                    route: 'items.price-groups.list',
                    isActive: true,
                },
                {
                    title: 'Item Group',
                    icon: 'fas fa-dolly',
                    route: 'items.groups.list',
                    isActive: true,
                },
                {
                    title: 'Item Tag',
                    icon: 'fas fa-dolly',
                    route: 'items.tags.list',
                    isActive: true,
                },

                {
                    title: 'Item coupons',
                    icon: 'fas fa-dolly',
                    route: 'items.coupons.list',
                    isActive: true,
                },

                {
                    title: 'Stock Count Jobs',
                    icon: 'fas fa-dolly',
                    route: 'items.stock-count-jobs',
                    isActive: true,
                },

                {
                    title: 'Stock Count Activity',
                    icon: 'fas fa-dolly',
                    route: 'items.stock-count-activity',
                    isActive: true,
                },

                {
                    title: 'Goods In Transit',
                    icon: 'fas fa-dolly',
                    route: 'items.goods-in-transit.list',
                    isActive: true,
                },
            ],
        },
        {
            name: 'Settings',
            icon: 'fas fa-cogs',
            route: null,
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'Store settings',
                    icon: 'fas fa-cog',
                    route: 'settings.store-setting.form',
                    isActive: true,
                },
                {
                    title: 'Store settings 2',
                    icon: 'fas fa-cog',
                    route: 'settings.store-setting.form2',
                    isActive: true,
                },
                {
                    title: 'Locations',
                    icon: 'fas fa-compass',
                    route: 'settings.locations.list',
                    isActive: true,
                },
                {
                    title: 'Areas',
                    icon: 'fas fa-dolly',
                    route: 'settings.areas.list',
                    isActive: true,
                },
                {
                    title: 'Item Location',
                    icon: 'fas fa-route',
                    route: 'settings.item-locations.list',
                    isActive: true,
                },
                {
                    title: 'Pos Terminals',
                    icon: 'fas fa-gifts',
                    route: 'settings.pos-terminals',
                    isActive: true,
                },
                {
                    title: 'Employees',
                    icon: 'fas fa-user-tie',
                    route: 'settings.employees.list',
                    isActive: true,
                },
                {
                    title: 'Tax rules',
                    icon: 'fas fa-percentage',
                    route: 'settings.taxes.list',
                    isActive: true,
                },
                {
                    title: 'Logistic Providers',
                    icon: 'fas fa-truck',
                    route: 'settings.v2.logistic-providers.list',
                    isActive: true,
                },
                {
                    title: 'Payment Methods',
                    icon: 'fas fa-money-check-alt',
                    route: 'settings.payment-methods.list',
                    isActive: true,
                },
                {
                    title: 'Ecommerce Accounts',
                    icon: 'fas fa-store',
                    route: 'settings.ecommerce.list',
                    isActive: true,
                },
                {
                    title: 'Payment Terms',
                    icon: 'fas fa-money-check-alt',
                    route: 'settings.payment-terms.list',
                    isActive: true,
                },
                {
                    title: 'Scan Type',
                    icon: 'fas fa-money-check-alt',
                    route: 'settings.scan-types.list',
                    isActive: true,
                },
                {
                    title: 'Job Order Task Type',
                    icon: 'fas fa-money-check-alt',
                    route: 'settings.job-order-task-types.list',
                    isActive: true,
                },
                {
                    title: 'Lucky Draws Gift Type',
                    icon: 'fas fa-gift',
                    route: 'settings.lucky-draws-gift-types.list',
                    isActive: true,
                },
                {
                    title: 'Logistic Provider Management',
                    icon: 'fas fa-percentage',
                    route: 'settings.couriers.list',
                    isActive: true,

                },
                {
                    title: 'Approval Limits',
                    icon: 'fas fa-money-check-alt',
                    route: 'settings.approval-limits.list',
                    isActive: true,
                },
                {
                    title: 'Approval Rules',
                    icon: 'fas fa-money-check-alt',
                    route: 'settings.approval-rules.list',
                    isActive: true,
                },
            ],
        },
        {
            name: 'Reports',
            icon: 'fas fa-chart-line',
            route: null,
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'Reports',
                    icon: 'fas fa-file-contract',
                    route: 'reports.list',
                    isActive: true,
                },

                {
                    title: 'AWB',
                    icon: 'fas fa-file-contract',
                    route: 'reports.awb',
                    isActive: true,
                },

                {
                    title: 'Category Sales',
                    icon: 'fas fa-file-contract',
                    route: 'reports.category-sales',
                    isActive: true,
                },

            ]
        },
        {
            name: 'Module Dashboards',
            icon: 'fas fa-chart-line',
            route: null,
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'Sales',
                    icon: 'fas fa-dollar-sign',
                    route: 'module-dashboards.sales-dashboard',
                    isActive: true,
                },
                {
                    title: 'Sales Reps',
                    icon: 'fas fa-dollar-sign',
                    route: 'module-dashboards.sales-rep-dashboard',
                    isActive: true,
                },
                {
                    title: 'Customers',
                    icon: 'fas fa-dollar-sign',
                    route: 'module-dashboards.customer-analysis-dashboard',
                    isActive: true,
                },
                {
                    title: 'Items',
                    icon: 'fas fa-cube',
                    route: 'module-dashboards.items-dashboard',
                    isActive: true,
                },
                {
                    title: 'Debtors',
                    icon: 'fas fa-user-friends',
                    route: 'module-dashboards.debtors-dashboard',
                    isActive: true,
                },
                {
                    title: 'Purchase Orders',
                    icon: 'fas fa-user-friends',
                    route: 'module-dashboards.purchase-orders-dashboard',
                    isActive: true,
                },
                {
                    title: 'Job Orders',
                    icon: 'fas fa-user-friends',
                    route: 'module-dashboards.job-orders-dashboard',
                    isActive: true,
                },
                {
                    title: 'Item Performance',
                    icon: 'fas fa-cube',
                    route: 'module-dashboards.item-performance',
                    isActive: true,
                },
                {
                    title: 'Daily item analysis',
                    icon: 'fas fa-cube',
                    route: 'module-dashboards.recent-item-sales-analysis',
                    isActive: true,
                },
                {
                    title: 'Transaction Summary',
                    icon: 'fas fa-cube',
                    route: 'module-dashboards.transaction-summary',
                    isActive: true,
                },
                {
                    title: 'Fulfillment',
                    icon: 'fas fa-cube',
                    route: 'module-dashboards.fulfillment',
                    isActive: true,
                },
            ]
        },
        {
            name: 'Tools',
            icon: 'fas fa-wine-bottle',
            route: null,
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'Place Holder',
                    icon: 'fas fa-file-contract',
                    route: 'tools.list',
                    isActive: true,
                },
            ]
        },

        {
            name: 'Scan Station',
            icon: 'fas fa-barcode',
            route: null,
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'Scan Document',
                    icon: 'fas fa-barcode',
                    route: 'scan-station.scan.list',
                    isActive: true,
                },
                {
                    title: 'Deactive Carton',
                    icon: 'fas fa-barcode',
                    route: 'scan-station.scan.deactive-barcode',
                    isActive: true,
                },
                {
                    title: 'Stock Count',
                    icon: 'fas fa-barcode',
                    route: 'scan-station.scan.stock-count',
                    isActive: true,
                },
            ],
        },

        {
            name: 'Integration',
            icon: 'fas fa-wine-bottle',
            route: null,
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'QuickBook',
                    icon: 'fas fa-file-contract',
                    route: 'integrations.quickbook.form',
                    isActive: true,
                },

            ]
        },
        {
            name: 'Marketing',
            icon: 'fas fa-gift',
            route: null,
            showChildren: false,
            hasChildren: true,
            menus: [
                {
                    title: 'Lucky Draw',
                    icon: 'fas fa-dice',
                    route: 'marketing.lucky-draws.list',
                    isActive: true,
                },
                {
                    title: 'Lucky Draw Users',
                    icon: 'fas fa-ticket-alt',
                    route: 'marketing.lucky-draw-users.list',
                    isActive: true,
                },

            ]
        },

    ]
}
