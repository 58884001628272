<template>
  <div id="kt_header" style="" class="header align-items-stretch">
    <div class="container-fluid d-flex align-items-stretch justify-content-between">
      <!--begin::Aside mobile toggle-->
      <div class="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
        <div class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
             id="kt_aside_mobile_toggle">
          <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
          <span class="svg-icon svg-icon-2x mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"/>
											<path opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"/>
										</svg>
									</span>
          <!--end::Svg Icon-->
        </div>
      </div>
      <!--end::Aside mobile toggle-->
      <!--begin::Mobile logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <router-link :to="{name: 'dashboard'}" class="d-lg-none">
          <!--          <img alt="Logo" src="/assets/media/logos/logo-2.svg" class="h-30px" />-->
        </router-link>
      </div>
      <!--end::Mobile logo-->
      <!--begin::Wrapper-->
      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <div class="d-flex align-items-stretch" id="kt_header_nav">
          <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu"
               data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
               data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end"
               data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true"
               data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
            <div
                class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                id="#kt_header_menu" data-kt-menu="true">
              <div class="menu-item me-lg-1">
                <router-link
                    :to="{ name: `logout` }"
                    type="button"
                    class="menu-link active py-3"
                >
                  <i class="fas fa-sign-out-alt fs-4 me-2"></i> Log out
                </router-link>
              </div>

<!--              Shifted to the user menu-->
<!--              <div class="menu-item me-lg-1">-->
<!--                <router-link-->
<!--                    :to="{ name: `integration.errors.list` }"-->
<!--                    type="button"-->
<!--                    class="menu-link active py-3"-->
<!--                >-->
<!--                  Integration Errors-->
<!--                </router-link>-->
<!--              </div>-->


              <div class="menu-item me-lg-1">
                <router-link
                    :to="{ name: `dashboard.approve` }"
                    type="button"
                    class="menu-link active py-3"
                >
                  Approve
                </router-link>
              </div>
                  <NotificationBell />
            </div>
          </div>
        </div>
        <!--end::Navbar-->
        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <!--begin::Toolbar wrapper-->
          <div class="d-flex align-items-stretch flex-shrink-0">
            <div class="d-flex align-items-center ms-1 ms-lg-3">
              <div class="menu-item me-lg-1">
                <h4 class="menu-link py-3 text-primary">Hello : {{user.name}}</h4>
              </div>
            </div>
            <div class='d-flex align-items-center ms-3 ms-lg-5' v-if='isAllowToDisplay'>
              <VueMultiselect
                  v-model='location'
                  :options='userLocations'
                  @open='getLocations'
                  track-by='id'
                  label='name'
                  @input='getLocations'
                  :hideSelected='true'
                  :allowEmpty='false'
                  @select='changeUserLocation'
                  @search-change="searchLocations"
              />
            </div>
          </div>
          <!--end::Toolbar wrapper-->
          <!--begin::User-->
          <user-menu></user-menu>
          <!--end::User -->
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import {defineAsyncComponent}                 from 'vue'
import VueMultiselect                         from 'vue-multiselect'

const UserMenu = defineAsyncComponent(() => import('./UserMenu'))
import {mapActions, mapState}                 from 'vuex'
import {getUserLocations, updateUserLocation} from '@/app/api/user.api'
import '@/app/css/vue-multiselect.css'
import {USER_LOCATION_CHANGED}                from '@/app/constants/action-type'
import hideLocationRoutes                     from '@/app/extra/hideLocationRoutes'
import {debounce}                             from "lodash";
import NotificationBell from "@/components/common/NotificationBell.vue";

export default {

  data: () => ({
    userLocations: [],
    location: null,
    isToggle: false,
  }),

  components: {
    UserMenu,
    VueMultiselect,
    NotificationBell,
  },

  computed: {
    ...mapState(['user', 'company']),
    isAllowToDisplay() {
      return !hideLocationRoutes.includes(this.$route.name)
    },
  },

  methods: {
    ...mapActions([USER_LOCATION_CHANGED]),

    searchLocations: debounce(function (keyword) {
      this.getLocations(keyword);
    }, 350, {'maxWait': 1000}),

    getLocations(keyword = null) {
      let qs = ''
      if (keyword) {
        qs = '?keyword=' + keyword
      }
      getUserLocations(qs).then(({data: {data}}) => {
        this.userLocations = data
      })
    },
    changeUserLocation(val) {
      if (val.id) {
        updateUserLocation(val.id).then(({data: {data}}) => {
              this[USER_LOCATION_CHANGED](data)
              this.$router.go()
            },
        )
      }
    },
  },

  mounted() {
    this.location = this.user.current_location;
  },

}
</script>

<style scoped>

</style>