<template>
    <div class="menu-item me-lg-1">
      <el-tooltip content="Notifications" placement="bottom">
        <router-link :to="{ name: `notification.list` }" type="button" class="menu-link active py-3"
          @click="resetNotificationCounter">
          <el-badge v-if="notificationCount > 0" :value="notificationCount" class="notification-badge">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAw0lEQVR4nO2UMQoCMRBFX6WVCHZiJV5GWxEvoWJtt6VHEwut1c7dI2izTWRgAmFxYR0TUPTDQNj8+W+TgcAvaQScgRMwTAHYAE5L1tHUApbAJQDIeqF7b2kAHIPgah2AvjW8HYTnwAzoaE11Hh5iOsk6CO892ZdvhXpWFsBem+XP6zRXz84CuGuzXEmduuq5WQB+kLF85kb3B3znFWXaVDbwlurNXg231LYJwBrurLPwqj7X0ZUHgGsKwESDpcYpAJ+pB5xPagF2EZqeAAAAAElFTkSuQmCC">
          </el-badge>
          <img v-else src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAw0lEQVR4nO2UMQoCMRBFX6WVCHZiJV5GWxEvoWJtt6VHEwut1c7dI2izTWRgAmFxYR0TUPTDQNj8+W+TgcAvaQScgRMwTAHYAE5L1tHUApbAJQDIeqF7b2kAHIPgah2AvjW8HYTnwAzoaE11Hh5iOsk6CO892ZdvhXpWFsBem+XP6zRXz84CuGuzXEmduuq5WQB+kLF85kb3B3znFWXaVDbwlurNXg231LYJwBrurLPwqj7X0ZUHgGsKwESDpcYpAJ+pB5xPagF2EZqeAAAAAElFTkSuQmCC">
        </router-link>
      </el-tooltip>
    </div>
  </template>
  
  <script>
  
  import { getUserNotificationsCount } from "@/app/api/common.api";
  import { ResetUserNotificationCount } from "@/app/api/common.api";
  
  export default {
    data() {
      return {
        notificationCount: 0,
        searchParam: {
          keyword: "",
        },
        search: "",
        rows: 30,
        page: 1,
        pagination: {
          itemPerPage: 30,
          total: 0,
        },
        loading: true,
      };
    },
    methods: {
  
      getDataFromApi() {
        let qs = `?page=${this.page}&rows=${this.rows}&keyword=${this.searchParam.keyword}`;
        getUserNotificationsCount(qs).then(({ data: { data } }) => {
          this.notificationCount = data;
        }).catch(function (error) {
          this.$message({ type: 'error', message: error });
        });
      },
  
      resetNotificationCounter() {
        this.notificationCount = 0;
        let qs = `?page=${this.page}&rows=${this.rows}&keyword=${this.searchParam.keyword}`;
        ResetUserNotificationCount(qs).then(({ data: { data } }) => {
          console.log(data)
          // this.notificationCount = data;
        }).catch(function (error) {
          this.$message({ type: 'error', message: error });
        });
      },
  
      // resetNotificationCount() {
      //    this.resetNotificationCounter();
      // },
    },
    mounted() {
      this.getDataFromApi();

      setInterval(() => {
        this.getDataFromApi();
      }, 60000000);

      setInterval(() => {
        this.resetNotificationCounter();
      }, 60000);

    },
  };
  </script>
  
  <style scoped>
  .notification-badge {
    .el-badge__content {
      background-color: #f56c6c;
    }
  }
  </style>
  