export default [
    'sales.quotations.edit',
    'sales.invoices.edit',
    'accounts.companies.edit',
    'items.item.edit',
    'items.item.inventory',
    'settings.employees.edit',
    'settings.ecommerce.edit',
    'items.receiving.edit',
    'items.stock-transfer.edit'
]
