import _helper from '@/app/extra/helper'

import {
    SET_USER,
    SET_COMPANY,
    SET_AUTH_TOKEN,
    RESET_USER,
    SET_SIDEBAR_OPEN,
    SET_MENU,
    SET_EXPIRY,
    SET_USER_LOCATION,
    SET_ACCESS,
} from '@/app/constants/mutations-types'

import {
    USER_LOGGED_IN,
    USER_LOGGED_OUT,
    UPDATE_USER_DATA,
    TOGGLE_SIDEBAR,
    USER_LOCATION_CHANGED,
    SET_USER_MENU,
    UPDATE_STATE,
} from '@/app/constants/action-type'
import { getUserInfo } from '@/app/api/user.api'

export default {
    [USER_LOGGED_IN]({ commit }, data) {
        let { token, user, isSidebarOpen, menu, access, expiry, company } = data
        _helper.setCookie('mdpos_token', token, 100)
        commit(SET_USER, user)
        commit(SET_MENU, menu)
        commit(SET_ACCESS, access)
        commit(SET_AUTH_TOKEN, token)
        commit(SET_SIDEBAR_OPEN, isSidebarOpen)
        commit(SET_MENU, menu)
        commit(SET_EXPIRY, expiry)
        commit(SET_EXPIRY, expiry)
        commit(SET_COMPANY, company)
    },

    [USER_LOGGED_OUT]({ commit }) {
        commit(RESET_USER)
        _helper.removeCookie('mdpos_token')
    },

    [UPDATE_USER_DATA]({ commit }) {
        getUserInfo()
            .then(({ data: { data } }) => {
                let { user, isSidebarOpen, menu, access, expiry, company } =
                    data
                commit(SET_USER, user)
                commit(SET_MENU, menu)
                commit(SET_ACCESS, access)
                commit(SET_SIDEBAR_OPEN, isSidebarOpen)
                commit(SET_MENU, menu)
                commit(SET_EXPIRY, expiry)
                commit(SET_EXPIRY, expiry)
                commit(SET_COMPANY, company)
            })
            .finally(() => {
                window.location.reload()
            })
    },

    [UPDATE_STATE]({ commit }, data) {
        let { user, isSidebarOpen, menu, access, expiry, company } = data
        commit(SET_USER, user)
        commit(SET_MENU, menu)
        commit(SET_ACCESS, access)
        commit(SET_SIDEBAR_OPEN, isSidebarOpen)
        commit(SET_MENU, menu)
        commit(SET_EXPIRY, expiry)
        commit(SET_EXPIRY, expiry)
        commit(SET_COMPANY, company)
    },
    [TOGGLE_SIDEBAR]({ commit }, data) {
        commit(SET_SIDEBAR_OPEN, data)
    },

    [USER_LOCATION_CHANGED]({ commit }, data) {
        commit(SET_USER_LOCATION, data)
    },

    [SET_USER_MENU]({ commit }, data) {
        commit(SET_MENU, data)
    },
}
